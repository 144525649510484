import React from 'react';
import { Helmet } from "react-helmet"

export default function Layout(props) {
  return <div>
      {props.children}
      <Helmet>
        <script type="text/javascript" src="/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
      </Helmet>
    </div>
}